import React, { FC, useCallback, useEffect, useState } from "react";
import Filters from "./filters";
import Table from "./table";
import { useAnswersApi } from "../../requests/AnswersApi";
import { Pageable } from "../../models/types/Pageable";
import { PageableInitial, SurveyInstanceFiltersInitial } from "../../helpers/ObjectConstants";
import { SurveyInstanceFilters, SurveyInstanceResponseType } from "../../models/types/SurveyInstanceFilters";
import { useParams } from "react-router-dom";
import { Empty } from "antd";
import { useAppContext } from "../../application/context";

const AnswersPage: FC = () => {
  const { shareId } = useParams();

  if (!shareId) {
    return (
      <Empty
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      />
    );
  }

  return <Container shareId={shareId} />;
};

const Container: FC<{ shareId: string }> = ({ shareId }) => {
  const { data: ctxData, password } = useAppContext();
  const { getSurveyInstances } = useAnswersApi(ctxData.shareType);
  const [pageable, setPageable] = useState<Pageable>(PageableInitial);
  const [filters, setFilters] = useState<SurveyInstanceFilters>(SurveyInstanceFiltersInitial);
  const [data, setData] = useState<SurveyInstanceResponseType | undefined>();

  const loadInstances = useCallback(() => {
    getSurveyInstances(shareId, pageable, filters, password).then(setData);
  }, []);

  useEffect(() => {
    loadInstances();
  }, [pageable]);

  return (
    <div>
      <Filters filters={filters} setFilters={setFilters} />
      <Table filters={filters} setFilters={setFilters} pageable={pageable} setPageable={setPageable} data={data} />
    </div>
  );
};

export default AnswersPage;
